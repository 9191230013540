import {controller, target} from '@github/catalyst'
import {fetchSafeDocumentFragment} from '../fetch'
import {requestSubmit} from '@github-ui/form-utils'

@controller
class OptionsPopoverElement extends HTMLElement {
  @target dropdownDetails: HTMLDetailsElement
  @target modalDetails: HTMLDetailsElement
  @target settingsModal: HTMLElement
  @target skuForm: HTMLFormElement
  @target resultMessage: HTMLElement
  @target errorMessage: HTMLElement
  @target exportDetails: HTMLDetailsElement | undefined
  @target publishDetails: HTMLDetailsElement | undefined
  @target publishForm: HTMLFormElement | undefined
  @target forkDetails: HTMLDetailsElement | undefined
  @target editorDetails: HTMLDetailsElement | undefined
  @target renameButton: HTMLButtonElement | undefined
  @target renameForm: HTMLFormElement | undefined
  @target renameInput: HTMLInputElement | undefined

  connectedCallback() {
    // eslint-disable-next-line custom-elements/no-dom-traversal-in-connectedcallback
    const dropdownList = document.getElementById('codespaces-dropdown-list') as HTMLElement
    if (dropdownList != null) {
      dropdownList.onscroll = () => this.updateDetailsOnScroll(0, dropdownList.scrollTop + window.pageYOffset)
      document.onscroll = () => this.updateDetailsOnScroll(0, dropdownList?.scrollTop + window.pageYOffset)
      onresize = () => this.updateDetailsOnScroll(0, dropdownList?.scrollTop + window.pageYOffset)
    }
  }

  reset(event: Event | undefined) {
    event?.preventDefault() // We don't want default details-dialog-close behavior, which resets the form.
    if (this.dropdownDetails) this.dropdownDetails.hidden = false
    if (this.modalDetails) this.modalDetails.hidden = true
    if (this.exportDetails) this.exportDetails.hidden = true
    if (this.publishDetails) this.publishDetails.hidden = true
    if (this.forkDetails) this.forkDetails.hidden = true
    if (this.editorDetails) this.editorDetails.hidden = true
    if (this.skuForm) this.skuForm.hidden = false
    while (this.resultMessage?.firstChild) {
      this.resultMessage.removeChild(this.resultMessage.firstChild)
    }
    if (this.resultMessage) this.resultMessage.hidden = true
    if (this.errorMessage) this.errorMessage.hidden = true
  }

  updateDetailsOnScroll(minScrollTop: number, scrollTop?: number) {
    const dropdownList = document.getElementById('codespaces-dropdown-list') as HTMLElement
    if (dropdownList != null) {
      this.reset(undefined)
      // Adjust margin top of the submenus based on scoll top
      const details: NodeListOf<HTMLElement> = document.querySelectorAll('details')
      for (const detail of details) {
        if (this.isSubMenu(detail) && detail.children[1]) {
          if (scrollTop && scrollTop > minScrollTop) {
            ;(detail.children[1] as HTMLElement).style.marginTop = `-${scrollTop}px`
          } else {
            ;(detail.children[1] as HTMLElement).style.marginTop = '0px'
          }
        }
      }
    }
  }

  isSubMenu(detail: HTMLElement) {
    return (
      detail.getAttribute('data-target') === 'options-popover.dropdownDetails' ||
      detail.getAttribute('data-target') === 'options-popover.editorDetails' ||
      detail.getAttribute('data-target') === 'options-popover.exportDetails' ||
      detail.getAttribute('data-target') === 'options-popover.publishDetails'
    )
  }

  showSettingsModal() {
    this.dropdownDetails.hidden = true
    this.modalDetails.open = true
    this.modalDetails.hidden = false
  }

  showExportModal() {
    this.dropdownDetails.hidden = true
    if (this.exportDetails) {
      this.exportDetails.open = true
      this.exportDetails.hidden = false
    }
    this.insertBranchExportComponent()
  }

  showEditors() {
    this.dropdownDetails.hidden = true
    if (this.editorDetails) {
      this.editorDetails.open = true
      this.editorDetails.hidden = false
    }
  }

  closeEditors() {
    this.dropdownDetails.open = true
    this.dropdownDetails.hidden = false
    if (this.editorDetails) {
      this.editorDetails.open = false
      this.editorDetails.hidden = true
    }
  }

  showPublishAndExportModal() {
    this.dropdownDetails.hidden = true
    if (this.publishDetails) {
      this.publishDetails.open = true
      this.publishDetails.hidden = false
    }
    this.insertBranchExportComponent()
  }

  showForkAndExportModal() {
    this.dropdownDetails.hidden = true
    if (this.forkDetails) {
      this.forkDetails.open = true
      this.forkDetails.hidden = false
    }
    this.insertBranchExportComponent()
  }

  showRenamePrompt() {
    if (this.renameButton && this.renameForm && this.renameInput) {
      const promptText = this.renameButton.getAttribute('data-rename-prompt')
      const currentDisplayName = this.renameButton.getAttribute('data-display-name')
      if (promptText && currentDisplayName) {
        const newDisplayName = prompt(promptText, currentDisplayName) || null
        const trimmedDisplayName = newDisplayName?.trim()
        if (trimmedDisplayName && currentDisplayName !== trimmedDisplayName) {
          this.renameInput.value = trimmedDisplayName
          requestSubmit(this.renameForm)
        }
      }
    }
  }

  async insertBranchExportComponent() {
    const container = this.querySelector<HTMLElement>('[data-branch-export-url]')
    if (!container) return

    const url = container.getAttribute('data-branch-export-url')
    if (!url) return

    const branchExportComponentHTML = await fetchSafeDocumentFragment(document, url)
    if (!branchExportComponentHTML) return

    // It would be nicer to use replaceChildren but it's too recent and still unsupported in TypeScript.
    container.textContent = ''
    container.appendChild(branchExportComponentHTML)
  }

  applyPublishParams() {
    if (this.publishForm) {
      const publishFormData = Object.fromEntries(new FormData(this.publishForm).entries())
      const exportForm = this.querySelector<HTMLFormElement>('[data-target="export-branch.form"]')
      if (exportForm) {
        const queryString = `?name=${publishFormData.name}&visibility=${publishFormData.visibility}`
        const updatedUrl = (exportForm.getAttribute('action') || '').split('?')[0] + queryString
        exportForm.setAttribute('action', updatedUrl)
      }
    }
  }
  openDeepLink(e: Event) {
    e.preventDefault()
    const el = e.target as HTMLElement
    const url = el.closest('button')?.getAttribute('data-update-url')
    // Open the url in the current window. Set to "_blank" instead of "_self" to open in a new window.
    if (url) window.open(url, '_self')
    this.reset(e)
  }
}
